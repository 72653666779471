import React, { ReactNode } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface VideoMaskProps {
  className?: string
  children: ReactNode
  link?: string
  onClick?: () => void
}

const VideoMask: React.FC<VideoMaskProps> = ({ children, className, link = '', onClick }) => {
  return (
    <div className={classnames('h-full w-full', styles.container, className)}>
      {link && (
        <a href={link} target="_blank" className="video_mask_content">
          <span className="video_mask_content_icon" />
        </a>
      )}
      {!link && (
        <span className="video_mask_content" onClick={onClick}>
          <span className="video_mask_content_icon" />
        </span>
      )}
      {children}
    </div>
  )
}

export default VideoMask
