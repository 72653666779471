import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import Layout from 'layouts/zh'
import AnalysisCard from 'components/Analysis/AnalysisCard'
import Button, { ButtonGroup } from 'components/Base/Button'
import Carousel from 'components/Carousel'
import ProductCards from 'components/HealthCom/ProductCards'
import VideoMask from 'components/VideoMask'
import {
  Banner,
  Seo,
  SummaryTitle,
  TabSwitcher,
  ScanCodeConsultButton,
  ImagePreview,
  Digital,
  VideoPlayer,
  DownloadCard,
  ImgDesc,
  TestimonyCard,
  PageBottomCard,
} from 'components/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { graphql } from 'gatsby'
import logo from './svg/logo.svg'
import * as styles from './index.module.less'

const AnalysisCloud: React.FC = (props: any) => {
  const { data } = props
  const { analysisCloudYaml } = data

  return (
    <Layout {...props}>
      <AnalysisCloudState {...analysisCloudYaml} />
    </Layout>
  )
}

export const AnalysisCloudState = (props) => {
  const { wxIcon, banner, part1, part2, part3, part4, part5, part6 } = props
  const curVideo = useRef<any>(null)
  const isMb = useIsWindowWidthSmaller()

  return (
    <>
      <Seo
        title="神策分析云｜驱动经营增长的全链路数据分析平台 - 神策数据产品"
        description="利用业务数据驱动业务全面增长，让营销数据快速支撑决策依据，从广告投放数据监控，到用户站内转化跟踪，形成有效的数据分析闭环。"
        keywords="分析云平台,广告推广数据监控,用户行为数据分析,营销数据分析"
        saTitle="首页 - 解决方案 - 数据分析云平台"
      />
      <div className={styles.analysisCloudRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={banner?.title}
            desc={banner?.desc}
            buttons={banner?.buttons}
            // img={part1.banner?.publicURL ?? part1.banner}
            className={classnames(styles.banner)}
            h1tag={true}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            curVideo={curVideo}
            duration="1:24"
            subTitle={banner?.subTitle}
            bannerSubTitleClassName={styles.bannerSubTitleClassName}
            bannerBtnsClass={styles.bannerBtnsClass}
          />
        </div>
        {isMb && (
          <div className="px-[2rem] bg-[#f9fafc] w-full h-full">
            <VideoMask className="rounded-[4px] overflow-hidden" onClick={() => curVideo?.current?.playVideo()}>
              <img className="w-full h-full" src={banner?.video_bg?.publicURL ?? banner?.video_bg} />
            </VideoMask>
          </div>
        )}
        <div className={styles.wWarrper}>
          {/* {isMb ? (
            <div>
              <SummaryTitle title="强大的分析引擎" />
              <SummaryTitle title="赋能从用户到经营分析" />
            </div>
          ) : (
            <SummaryTitle title="强大的分析引擎，赋能从用户到经营分析" />
          )} */}
          <SummaryTitle title="全链路、全角色、全场景的分析云" />
          <div className={styles.partContent}>
            <TabSwitcher data={part1.data} longTitle align="space-between" mbShowShadow={false} autoWAndH />
          </div>
        </div>
        <div className={styles.bWarrper}>
          {isMb ? (
            <div>
              <SummaryTitle title="在每个场景中感知业务变化" />
              <SummaryTitle title="洞察用户需求" />
            </div>
          ) : (
            <SummaryTitle title="在每个场景中感知业务变化，洞察用户需求" />
          )}
          <div className="flex justify-center md:mt-[51px] pl-[20px] pr-[20px]">
            <Digital
              data={part2?.list}
              buttonText={part2?.buttonText}
              wxIcon={wxIcon}
              moreIcon={part2?.moreIcon}
              buttonNext={part2?.buttonTextNext}
              link={part2?.link}
              whiteWxBtn
              showStatic
            />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <DownloadCard
            title={part3?.title}
            desc={part3?.desc}
            linkText={part3?.linkText}
            link={part3?.link}
            bg={part3?.bg}
            mb_bg={part3?.mb_bg}
          />
          <div className="md:mt-[100px] mt-[6rem]">
            {isMb ? (
              <div>
                <SummaryTitle title="强大的分析引擎" />
                <SummaryTitle title="赋能从用户到经营分析" />
              </div>
            ) : (
              <SummaryTitle title="强大的分析引擎，赋能从用户到经营分析" />
            )}
          </div>
          <div className="flex items-center flex-col md:mt-[60px] mt-[2.6rem] pl-[20px] pr-[20px] pb-[6rem] md:pb-0">
            <ImagePreview
              imageUrl={part3?.report?.img?.publicURL ?? part3?.report?.img}
              pcImgClassName={styles.reportImg}
            />
            {!isMb && (
              <ScanCodeConsultButton
                className="mt-[60px]"
                buttonText="立即咨询"
                imgBottomText="微信扫码 即刻咨询"
                qrcode={wxIcon?.publicURL ?? wxIcon}
              />
            )}
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="AI 智能，让分析可以预测未来" />
          <div className="flex justify-center md:mt-[60px] mt-[2.6rem] pl-[20px] pr-[20px]">
            <ImgDesc descArr={part4.descArr} imgUrl={part4.imgUrl} wxIcon={wxIcon} leftBtnLink={part4.leftBtnLink} />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="更多赋能，驱动您的业务行动" />
          <div className="lg:w-[1200px] lg:mt-[60px] mt-[26px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part5?.dataSource?.map((item) => {
              return <AnalysisCard className="mt-[20px] lg:mt-0" {...item} key={item?.title} />
            })}
          </div>
          <ButtonGroup className="text-center pt-[60px]">
            <Button href={part5?.left_btn_href} btnType="primary">
              {part5?.left_btn_text}
            </Button>
            <Button href={part5?.right_btn_href} target="_blank" btnType="primary" ghost>
              {part5?.right_btn_text}
            </Button>
          </ButtonGroup>
        </div>
        <div className={styles.bWarrper}>
          <div className="flex justify-center md:mt-[0px] mt-[3rem] pl-[20px] pr-[20px]">
            {/* <ImagePreview imageUrl={part4.img?.publicURL ?? part4.img} alt={part4?.alt || ''} /> */}
            <TestimonyCard {...part6} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <Carousel img={logo} className={styles.carousel} showMask />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="更多相关内容" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <ProductCards data={['神策数界平台', '神策营销云', '体验产品 Demo', '下载干货资料']} />
          </div>
        </div>
        <PageBottomCard
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
        />
        <VideoPlayer
          videoOpt={{
            sources: 'https://ow-file.sensorsdata.cn/www/home/AnalyticsCloud%202023.mp4',
          }}
          fuRef={curVideo}
        />
      </div>
    </>
  )
}

export default AnalysisCloud

export const query = graphql`
  query {
    analysisCloudYaml {
      wxIcon
      banner {
        title
        subTitle
        desc
        video_bg {
          publicURL
        }
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      part1 {
        longTitle
        data {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
            }
            introductionList {
              content
            }
          }
        }
      }
      part2 {
        buttonText
        buttonTextNext
        link
        list {
          desc
          title
          icon {
            publicURL
          }
        }
      }
      part3 {
        title
        desc
        linkText
        bg {
          publicURL
        }
        mb_bg {
          publicURL
        }
        link
        report {
          img {
            publicURL
          }
        }
      }
      part4 {
        descArr {
          title
          desc
        }
        imgUrl {
          publicURL
        }
        leftBtnLink
      }
      part5 {
        dataSource {
          title
          desc
          img_url {
            publicURL
          }
        }
        left_btn_text
        left_btn_href
        right_btn_text
        right_btn_href
      }
      part6 {
        imgUrl {
          publicURL
        }
        imgAlt
        title
        text
        signature
      }
    }
  }
`
