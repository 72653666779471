import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

/**
 * className 类名
 * img_url 图片路径
 * title 卡片标题
 * desc 卡片内容
 */
interface AnalysisCardProps {
  className?: string
  img_url?: string | { publicURL: string }
  title?: string
  desc?: string
}

const AnalysisCard: React.FC<AnalysisCardProps> = ({ className, img_url, title, desc }) => {
  return (
    <div className={classnames(styles.card, 'bg-[#fff] w-full h-[324px] lg:w-[280px] lg:h-[306px]', className)}>
      <p className="px-[1px] pt-[1px]">
        <img
          className="rounded-t-[4px] w-full h-[212px] lg:h-[175px]"
          src={typeof img_url === 'string' ? img_url : img_url?.publicURL}
          alt={title}
        />
      </p>
      <div className="pt-[12px] lg:pt-[18px] px-[14px] lg:px-[22px]">
        <p className="text-[16px] lg:text-[18px] font-medium text-[#181818] leading-[22px] lg:leading-[25px]">
          {title}
        </p>
        <p className="text-[14px] text-[#475669] leading-[20px] line-clamp-3 mt-[6px]">{desc}</p>
      </div>
    </div>
  )
}

export default AnalysisCard
