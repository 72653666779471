// extracted by mini-css-extract-plugin
export var analysisCloudRoot = "index-module--analysisCloudRoot--8JZs1";
export var bWarrper = "index-module--bWarrper--alJ2W";
export var banner = "index-module--banner--PPmeZ";
export var bannerBtnsClass = "index-module--bannerBtnsClass--AqgGT";
export var bannerDesc = "index-module--bannerDesc--DtdUW";
export var bannerSubTitleClassName = "index-module--bannerSubTitleClassName--vSV-n";
export var bannerTitle = "index-module--bannerTitle--qrnwu";
export var bannerWrapper = "index-module--bannerWrapper--vJANd";
export var carousel = "index-module--carousel--0whFW";
export var carouselWrapper = "index-module--carouselWrapper--t6WUD";
export var partContent = "index-module--partContent--O4HAm";
export var reportImg = "index-module--reportImg--jCf21";
export var wWarrper = "index-module--wWarrper--bVER3";